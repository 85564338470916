<template>
  <div>
    <v-parallax dark src="/images/main-backgroud.png" class="parallax  mx-auto my-12" height="300">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 style="font-size: 64px;">
            Archpkg
          </h1>
          <h4 class="subheading">
            {{ $lang.messages.pages.main.tagline }}
          </h4>
        </v-col>
      </v-row>
    </v-parallax>
    <div class="app-content mx-auto my-12" v-html="$lang.messages.pages.main.content">
    </div>
    <AppFooter/>
  </div>
</template>

<script>


export default {
  name: 'MainPage',
  data() {
    this.$lang.setLang('en');
    return {
    }
  }
}
</script>

<style scoped>

.parallax {
  max-width: 1024px;
}

.footer {
  max-width: 1024px;
}

.footer-column {
  height: 280px;
}

.link-icon {
  max-width: 64px;
  margin-bottom: 16px;
}

.column-item {
  margin-top: 16px;
  list-style-type: none;
}

.column-item li {
  margin-left: -24px;
}
</style>
