module.exports = {
    account: {
        login: 'Login',
        logout: 'Logout',
        login_github_success: 'Successfully logged into your GitHub account!',
        login_fail_success: 'Failed to login to GitHub account!'

    },
    repo: {
        id: 'ID repo',
        repository: 'Repository',
        my_packages: 'My packages',
        package_id: 'Package ID',
        package_desc: 'Description',
        package_repo: 'Repo',
        package_versions: 'Versions',
        package_actions: 'Actions',
        
        package_publish_select_repo: 'Select the GitHub repository',
        package_prepare_to_publish: 'Prepare to publish',
        package_prepare: 'Prepare',
        package_fix_errors: 'Correction of identified errors',
        package_publish: 'Publish package',
        package_publish_to_repository: 'Publishing to the repository',
        package_user_check: 'Check the packet metadata for correctness',

        package_publish_header: 'Publishing a package',
        package_publish_list: 'List of published packages',

        package_publish_repo_description: 'Select a repository that contains a package for publication and has at least one release. Only releases posted in this repository will be registered by archpkg to resolve dependencies.',
        package_publish_prepare_description: 'Before publishing your package, it will be checked for errors. If any errors are found, you will be able to correct them. Even if some versions have issues, you can still publish the releases that are error-free.',
        package_publish_description: 'After publication, the package will be available for download via archpkg. Please note that the publishing process in the registry may take some time.',
        package_for_publish_description: 'The following package versions can be published:',
        package_publish_no_see_repo: 'Why can\'t I see the required repository?',

        list: 'List of repo',
        validation_id_required: 'ID of repo is required',
        validation_id_repo_no_found: 'Repo with this ID not found',
        validation_id_repo_no_suitable: 'No suitable repo ID',
        validation_id_length: 'The identifier must be from 2 to 128 characters',
        validation_id_unavailable: 'The package ID is unavailable',
        validation_id_checking: 'Checking ID availability',
        validation_desc_required: 'Description of package is required',

        package_published_header: 'Published',
        package_published_info: 'Your package is published!',

        repo_is_unavailable: 'The repository ${repo} is unavailable now!',

    },
    releases: {
        tag_name: 'Tag name',
        error_info: 'Error information',
        no_errors: 'No errors',
        unknown_error: 'Unknown error ${error_code}',
        error_duplicate_version_in_releases: 'The package version ${version} is duplicated in releases',
        error_no_any_release: 'Nothing to publish. The repository is not contain any release.',
        error_no_manifest: 'dochub.yaml file not found',
        error_manifest_schema: 'The dochub.yaml file does not match the required format',
        error_multipackages: 'The distribution contains several packages: ${packages}',
        error_limit_size_manifest: 'dochub.yaml file size exceeds ${limit_size}',
        how_fix_errors: 'To correct errors, make the necessary adjustments to the GitHub repository and click the "Refresh" button.'

    },
    search: {
        search: 'Search',
        search_packages: 'Search packages',
        no_found: 'No found.',
        no_found_retry: 'Please change your request and try again.',

    },
    prompts: {
        tbd: 'Under construction...',
        get_started: 'How to publish?',
        no_found_repo: `
            <h3>I can't see the repository I need.</h3>
            <p>
                Only repositories to which you have the appropriate access level are displayed in the list.
                There are two types of repositories with different requirements:
            </p>
            <ul>
            <li><strong>Organization repositories:</strong> you must have maintainer rights to access them.</li>
            <li><strong>Personal repositories:</strong> you need the right to push to access them.</li>
            </ul>
            <h3>I am a maintainer in the organization but still can't see the repository.</h3>
            <p>
                When logging in through GitHub, you will be asked to consent to access your data.
                <strong>You need to separately provide access to information about organizations.</strong>
                To do this, you will need to select the corresponding organization at the bottom of the form and confirm access provision.        
            </p>
        `
    },

    pages: {
        how_to_publish: `
        <h1>Publishing an Architectural Package</h1>
        <p>
            Archpkg is structured very simply. It is a registry of GitHub repositories where architectural packages created using <a target="_blank" href="https://dochub.info">DocHub</a> are located. 
            Repository owners independently decide whether to include their repository in the archpkg registry.
        </p>
        <p>
            Despite its simplicity, it allows the community using the "architecture as code" approach to accumulate, catalog, and exchange accumulated experience expressed in architecture code.
            The idea of archpkg is borrowed from proven package managers such as <a target="_blank" href="https://www.npmjs.com/">npm</a> and <a target="_blank" href="https://pypi.org/project/pip/">pip</a>.
        </p>
        <p>
            Useful resources:
            <ul>
            <li>
                You can view an example of a published architectural package <a target="_blank" href="https://github.com/rpiontik/DocHubExamples">here</a>.
            </li>
            <li>
                Documentation for using archpkg can be found <a target="_blank" href="https://www.npmjs.com/package/archpkg">here</a>.
            </li>
            </ul>
        </p>
        <h2>Preparing for Publication</h2>
        <p>
            1. Create a project with architectural code using <a target="_blank" href="https://dochub.info">DocHub</a> that you want to share with the community. <br>
            2. Place a correctly filled out file <a href="#dochub_yaml">dochub.yaml</a> at the root of the project. This file is crucial for publishing the package.
            Example <a target="_blank" href="https://github.com/rpiontik/DocHubExamples/blob/main/dochub.yaml">here</a>.<br>
            3. Publish the project on GitHub.<br>
            4. Make sure to release a version. Example of a prepared repository <a href="https://github.com/rpiontik/DocHubExamples">here</a>.<br>
        </p>
        <p>
            <strong>IMPORTANT:</strong> If your project uses archpkg dependencies, you do not need to add their source code to your project on GitHub.
            Simply specify these dependencies in the "dependencies" section in the dochub.yaml file. More details <a href="#dochub_yaml">below</a>.
            It is advisable to include the "_metamodel_" folder with the source code of dependencies in the project's .gitignore.
        </p>
        <p>
            Now your package is ready for publication in the registry.
        </p>
        <h2>Publication in the Registry</h2>
        <p>
            1. Log in to the portal using your GitHub account.
        </p>
        <p>
            When providing consent to access your account data, make sure that access is also granted to the necessary organizations. Access to them is not automatically provided.
            You need to separately grant access at the bottom of the form.
        </p>
        <p>
            2. After logging in, on the left, the developer menu will appear. Select "My Packages".
            You will be taken to a list of already published packages available to you.
        </p>
        <p>
            <strong>ATTENTION:</strong> Even if you are logging into the archpkg portal for the first time, the list may contain entries. This is possible if you have the necessary access to repositories previously published by another user. This functionality
            allows you to manage the publication of packages within your trusted group.
        </p>
        <p>
            3. Above the list of packages, click on the "Publish Package" button. You will be taken to the publication form.<br>
            4. Follow the instructions on the form.<br>
            5. After successfully completing the publication, a new entry will appear in the list of packages.
        </p>
        <p>
            Congratulations! Your package has been published.
        </p>
        



        <h2>Release of a New Package Version</h2>
        <p>
            If a new release of your package is available in your repository, the registry will not update automatically. 
            You need to update the information manually. Here's how:
        </p>
        <p>
            1. Log in to the portal using your GitHub account.<br>
            2. After logging in, on the left side, the developer menu will appear. Select <a href="/my_packages">"My Packages"</a>. 
                You will be taken to a list of your already published packages.<br>
            3. Find the necessary entry in the list and click the "Update Package" button (cloud icon on the right).<br>
            4. Follow the instructions on the form.<br>
            5. After successful publication, the updated entry will appear in the list of packages.
        </p>
        <p>For automating the process, use the <a href="/pages/api">API</a>.</p>

        <h2 id="dochub_yaml">dochub.yaml File Format</h2>
        The content of the dochub.yaml file determines all the information about the published package. For each release, the file can be unique, including changing the package identifier.<br><br>
<pre class="code">
<span class="comment"># Project metadata</span>
$package:                                     <span class="comment"># Mandatory package metadata section</span>
  &lt;package identifier&gt;:                       <span class="comment"># Package identifier must match regex ^[0-9a-zA-Z­]3,128</span>
       version: &lt;package version&gt;             <span class="comment"># Package version in &lt;MAJOR&gt;.&lt;MINOR&gt;.&lt;PATCH&gt; format, more details <a target="blank" href="https://semver.org/">here</a></span>
       vendor: &lt;package vendor&gt;               <span class="comment"># Information about the package vendor</span>
       name: &lt;brief package name&gt;             <span class="comment"># Used for indexing in the registry</span>
       description: &lt;package description&gt;     <span class="comment"># Used for indexing in the registry</span>
       dependencies:                          <span class="comment"># Dependencies on other published packages are specified here</span>
        &lt;package identifier&gt;: &lt;version&gt;       <span class="comment"># Version specified in &lt;MAJOR&gt;.&lt;MINOR&gt;.&lt;PATCH&gt; format, more details <a target="blank" href="https://semver.org/">here</a></span>
                                              <span class="comment"># Rules for selecting packages can be specified</span>
                                              <span class="comment"># >   above the specified version, e.g., >1.2.11</span>
                                              <span class="comment"># >=  above or equal to the specified version, e.g., >=1.2.11</span>
...
      </pre>
      <strong>ATTENTION</strong>: Only one package identifier entry is allowed during publication.<br><br>
      <p>
        Example file <a target="_blank" href="https://github.com/rpiontik/DocHubExamples/blob/main/dochub.yaml">dochub.yaml</a>
        <pre class="code">
$package:
  dochub-examples:
    name: DocHub Usage Examples
    vendor: DocHub Community
    description: >
      The repository contains examples of using the DocHub architecture management tool.
      The repository is developed by the community.
    version: 1.2.0
    dependencies:
      dochub: ">=3.8.0"

imports:
  - src/C4Model/dochub.yaml      
       </pre>
      </p>              
        `,        
        main: {
            tagline: 'Let\'s design the future together!',
            content: `
            <p>
            Welcome to the <a target="_blank" href="https://dochub.info/main">DocHub</a> architectural code repository!
            </p>
            <p>
                Archpkg is a package manager. It provides dependency management for architectural repositories created
                using the "Architecture as Code" approach. It allows for quick and convenient installation, updating, and
                removal of packages necessary for the functioning of the architectural repository.
            </p>
            <p>
                Archpkg is designed to foster the development of architectural capabilities by an open community.
                It aims to facilitate the exchange of experiences and knowledge expressed in architectural code among community members.
            </p>
            <p>
                Archpkg operationalizes the concept of an adaptive metamodel. More information about it and the package structure can be found 
                <a target="_blank" href="https://dochub.info/entities/docs/blank?dh-doc-id=dochub.flex_metamodel">here</a>.
            </p>
        
            <p>
                Archpkg is a JavaScript CLI utility that requires <a target="_blank" href="https://docs.npmjs.com/about-npm">npm</a> and 
                <a target="_blank" href="https://nodejs.org/en/">nodejs</a> to function. Up-to-date information on its requirements and
                usage instructions can be found on the utility's page in the npm repository - 
                <a target="_blank" href="https://www.npmjs.com/package/archpkg">https://www.npmjs.com/package/archpkg</a>
            </p>            
            `
        },
        footer: {
            support: 'Support',
            support_telegram: 'Telegram group',
            support_github: 'GitHub Issue',
            support_bug: 'Bug report',

            community: 'Community',
            community_telegram: 'Telegram',
            community_channel: 'Telegram group',
            community_articles: 'Articles', 
            community_videos: 'Video', 

            policies: 'Terms & Policies',
            policies_principe: 'Development principles',
            policies_license: 'License'
        }
    },
    actions: {
        create: 'Create',
        refresh: 'Refresh',
        package_update: 'Update package',
    }
}