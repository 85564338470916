import cookie from 'vue-cookie';
import consts from '@/consts';

export default {
  state () {
    return {
      alerts: [],
      search: {
        text: null
      },
      account: {
        isLogin: cookie.get(consts.account.isLogin) || null,
        avatar_url: null,
        avatar_login: null
      }
    }
  },
  mutations: {
    setSearchText (state, text) {
      state.search.text = text;
    },
    refreshLoginStatus (state) {
      state.account.isLogin = cookie.get(consts.account.isLogin) || null;
      state.account.avatar_url = cookie.get(consts.account.avatar_url) || null;
      state.account.avatar_login = cookie.get(consts.account.avatar_login) || null;
    },
    pushAlert (state, alert) {
      state.alerts.push(alert);
    },
    cleanAlert (state) {
      state.alerts = [];
    }
  }
};

