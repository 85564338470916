
import request from '@/requests/request';
import env from '@/env';

export default {
  // Запрашивает токен доступа к GitHub
  async login(code) {
    const url = new URL('github/oauth/login', env.backend.url);
    const response = await request({
      url,
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      data: {
        code
      },
      withCredentials: true
    });
    return response.data;
  },
  // Выход из учетки
  async logout() {
    const url = new URL('github/oauth/logout', env.backend.url);
    const response = await request({
      url,
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      withCredentials: true
    });
    return response.data;
  }

};