<template>
  <div>
    <div class="app-header  mx-auto my-12">
      <div v-if="isCorrect">
        <h1>{{ $lang.messages.account.login_github_success }}</h1>
        <v-progress-linear indeterminate color="cyan"></v-progress-linear>
      </div>
      <div v-else>
        <h1 style="color: red;">{{ $lang.messages.account.login_github_fail }}</h1>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>

import consts from '@/consts';
import githubAPI from '@/components/oauth/api';
import env from '@/env';

export default {
  name: 'GitHubCallback',
  mounted() {
    if (this.isCorrect) {
      const state = this.$route.query.state;
      githubAPI.login(this.$route.query.code)
        .then((token) => {
          this.token = token;
          this.$store.commit('refreshLoginStatus');
          const redirectId = `redirect_${state}`;
          const redirect = this.$cookie.get(redirectId) || '/';
          this.$cookie.delete(redirectId);
          env.oauth.github.cleanLoginURL();
          setTimeout(() => this.$router.push({ path: redirect }), 1000);
        });
    } else setTimeout(() => this.$router.push({ name: 'main' }), 1000);
  },
  data() {
    return {
      token: null,
      isCorrect: this.$route.query.state === this.$cookie.get(consts.oauth.github.stateCookie)
    }
  }
}
</script>

<style></style>
