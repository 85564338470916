<template>
  <v-autocomplete v-model="selectedRepo" :items="items" :loading="isLoading" :search-input.sync="search" chips clearable
    hide-selected item-text="name" item-value="name" :label="$lang.messages.repo.id" dense :rules="repoIdRules"
    :disabled="disabled">
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          {{ $lang.messages.repo.validation_id_repo_no_suitable }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip v-bind="attr" :input-value="selected" color="blue-grey" class="white--text" v-on="on">
        <v-icon left>
          mdi-source-repository
        </v-icon>
        <span v-text="item.name"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar color="indigo" class="text-h5 font-weight-light white--text">
        {{ item.name.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
        <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-source-repository</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>

import backendAPI from '@/backend/api';

export default {
  name: 'RepoSelector',
  props: {
    value: Object,
    disabled: Boolean,
    initRepo: String
  },
  data() {
    return {
      isLoading: false,
      items: [],
      model: null,
      search: null,
      repoIdRules: [
        v => !!v || this.$lang.messages.repo.validation_id_required,
        v => !!this.getItemByName(v) || this.$lang.messages.repo.validation_id_repo_no_found
      ]
    }
  },

  computed: {
    selectedRepo: {
      get() {
        return this.model || this.value?.name;
      },
      set(v) {
        this.model = v;
        this.$emit('input', this.getItemByName(v));
      }
    }
  },

  mounted() {
    this.reload();
  },

  methods: {
    getItemByName(name) {
      return this.items.find((item) => item.name === name);
    },
    reload() {
      this.isLoading = true

      // Lazily load input items
      backendAPI.fetchUserRepoList()
        .then(list => {
          this.items = list;
          if (this.initRepo && !this.model) {
            this.model = this.initRepo;
            const item = this.getItemByName(this.model);
            if (item)
              this.$emit('input', this.getItemByName(this.model));
            else {
              this.$store.commit('pushAlert', {
                type: 'network-error',
                content: this.$lang.manager.template(this.$lang.messages.repo.repo_is_unavailable, {repo: this.model})
              });
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style></style>
