import Vue from 'vue';
import Router from 'vue-router';
import Main from '../components/Main.vue';
import oauthRoutes from '@/components/oauth/routes';
import Repository from '@/components/repository/Repository.vue';
import Search from '@/components/repository/Search.vue';
import packagePage from '@/components/repository/Package.vue';
import Packages from '@/components/packages/Packages.vue';
import PublicationPackage from '@/components/packages/Publication.vue';
import HowToPublish from '@/components/pages/HowToPublish.vue';
import API from '@/components/pages/API.vue';

const middleware = (route) => {
  window.$lang?.refresh();
	return route.params;
};

const routes = [
  {
    name: 'main',
    path: '/',
    component: Main,
    props: middleware
  },
  {
    name: 'repository',
    path: '/repository',
    component: Repository,
    props: middleware
  },
  {
    name: 'search',
    path: '/search/:text',
    component: Search,
    props: middleware
  },
  {
    name: 'packagePage',
    path: '/package/:packageId',
    component: packagePage,
    props: middleware
  },
  {
    name: 'myPackages',
    path: '/my_packages',
    component: Packages,
    props: middleware
  },
  {
    name: 'publicationPackage',
    path: '/my_packages/publication',
    component: PublicationPackage,
    props: middleware
  },
  {
    name: 'updatePackage',
    path: '/my_packages/publication/:initRepo',
    component: PublicationPackage,
    props: middleware
  },
  {
    name: 'howToPublish',
    path: '/pages/how-to-publish',
    component: HowToPublish,
    props: middleware
  },
  {
    name: 'api',
    path: '/pages/api',
    component: API,
    props: middleware
  },

].concat(oauthRoutes);


const routeConfig = {
  mode: 'history',
	scrollBehavior() {
		window.scrollTo(0, 0);
	},
	routes
};

Vue.use(Router);

export default new Router(routeConfig);