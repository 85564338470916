import Vue from 'vue'
import Vuex from 'vuex';
import App from './components/App.vue'
import router from './router/index';
import cookie from 'vue-cookie';
import vuetify from './plugins/vuetify'
import langs from './langs';
import storage from './storage/main';
import lang from 'vuejs-localization';
import footer from './components/Footer.vue';

lang.requireAll(require.context('./langs', true, /\.js$/));

Vue.use(lang);
Vue.use(cookie);
Vue.use(Vuex);

Vue.component('AppFooter', footer);

Vue.config.productionTip = false;

window.$vuex = new Vuex.Store(storage);


const app =new Vue({
  router,
  vuetify,
  store: window.$vuex,
  render: h => h(App)
});

app.$mount('#app');

window.$lang = langs(app);

