
import request from '@/requests/request';
import env from '@/env';

export default {
  // Запрашивает токен доступа к GitHub
  async fetchUserPackages() {
    const url = new URL('user/packages/own', env.backend.url);
    const response = await request({
      url: url.toString(),
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
      withCredentials: true
    });
    return response.data;
  },

  // Возвращает список доступных пользователю реп
  async fetchUserRepoList() {
    const url = new URL('user/repo/list', env.backend.url);
    return (await request({
      url: url.toString(),
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
      withCredentials: true
    })).data;
  },

  // Создает дистрибутив пакета
  async makePackageDistribution(repo) {
    const url = new URL('user/repo/make_distribution', env.backend.url);
    return (await request({
      url: url.toString(),
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      data: {
        repo
      },
      withCredentials: true
    })).data;
  },

  // Публикует дистрибутив пакета
  async publishPackageDistribution(distrib) {
    const url = new URL('user/repo/publish_distribution', env.backend.url);
    return (await request({
      url: url.toString(),
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      data: distrib,
      withCredentials: true
    })).data;
  },

  // Поиск пакетов репозитории
  async searchPackage(text, page = 0) {
    const url = new URL(`repo/search/${encodeURIComponent(text)}?page=${page}`, env.backend.url);
    const result = await request({
      url: url.toString(),
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
      withCredentials: true
    });

    return {
      more: result.status === 206,
      items: result.data
    }
  },

};