<template>
  <div>
    Page of package {{ packageId }}
  </div>
</template>
<script>

export default {
  name: 'PackagePage',
  props: {
    packageId: String
  },
  data() {
    return {};
  },
}
</script>

<style></style>
