<template>
  <div class="search-item">
    <h3><a target="_blnk" :href="makeRepoURL()">{{ packageId }}@{{ version }}</a></h3>
    <v-subheader>{{ name }}</v-subheader>
    <p>
      {{ description }}
    </p>
    <v-divider></v-divider>
  </div>
</template>

<script>

export default {
  name: 'RepositorySearchItem',
  props: {
    packageId: String,
    name: String,
    description: String,
    vendor: String,
    version: String,
    repo: String
  },
  data() {
    return {
    };
  },
  methods: {
    makeRepoURL() {
      return `https://github.com/${this.repo}`;
    }
  }
}
</script>

<style scoped>

.search-item {
  margin-top: 8px;
  margin-bottom: 8px;

}

</style>
