<template>
  <v-dialog v-model="show" min-width="500" max-height="400">

    <v-card>
      <v-toolbar color="primary" dark>
        {{ $lang.messages.repo.package_published_header }}
      </v-toolbar>

      <v-card-text>
        <div class="text-h3 pa-12">{{ $lang.messages.repo.package_published_info }}</div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="goToList">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'PackagePublished',
  data() {
    return {
      show: true
    }
  },
  methods: {
    goToList() {
      this.$router.push({name : 'myPackages'});
    }
  }
}
</script>

<style></style>
