<template>
  <v-radio-group
      v-model="language"
      style="padding-left: 12px;"
    >
      <v-radio v-for="lang in availableLangs"
        :label="lang"
        :value="lang"
        :key="lang"
      ></v-radio>
    </v-radio-group>
</template>


<script>

export default {
  name: 'CurrentLanguage',
  components: {
    
  },
  methods: {
  },
  computed: {
    availableLangs() {
      return this.$lang.manager.getAvailableLangs();
    },
    language: {
      get() {
        return this.$lang.manager.getCurrentLang();
      },
      set(selected) {
        this.$lang.manager.setCurrentLang(selected);
      }
    }
  },
  data() {
    return {
    };
  }
}
</script>

<style scoped>
.header-logo {
  height: 42px;
  width: auto;
}

.account {
  max-height: 100%;
}

.account .v-avatar {
  margin-top: 4px;
}
</style>
