module.exports = {
    account: {
        login: 'Войти',
        logout: 'Выйти',
        login_github_success: 'Успешный вход в учетную запись GitHub!',
        login_fail_success: 'Не удалось войти в учетную запись GitHub!'
    },
    repo: {
        id: 'Идентификатор репозитория',
        repository: 'Репозиторий',
        my_packages: 'Мои пакеты',
        package_id: 'Идентификатор пакета',
        package_desc: 'Описание',
        package_repo: 'Репозиторий',
        package_versions: 'Версии',
        package_actions: 'Действия',

        package_publish_select_repo: 'Выбор GitHub репозитория',
        package_prepare_to_publish: 'Подготовка для публикации',
        package_prepare: 'Подготовить',
        package_publish: 'Опубликовать пакет',
        package_publish_to_repository: 'Публикация в репозитории',
        package_fix_errors: 'Устранение выявленных ошибок',
        package_user_check: 'Проверка корректности данных дистрибутива',

        package_publish_header: 'Публикация пакета',
        package_publish_list: 'Список опубликованных пакетов',

        package_publish_repo_description: 'Выберите репозиторий для публикации, в котором присутствует пакет для публикации и имеется хотя бы один релиз. Только релизы, размещенные в данном репозитории, будут зарегистрированы archpkg для разрешения зависимостей.',
        package_publish_prepare_description: 'Перед публикацией вашего пакета он будет проверен на ошибки. В случае обнаружения ошибок, вы сможете их исправить. Даже если в некоторых версиях будут найдены проблемы, вы сможете опубликовать те релизы, которые не содержат ошибок.',
        package_publish_description: 'После публикации, пакет будет доступен для загрузки через archpkg. Пожалуйста, имейте в виду, что процесс публикации в реестре может занять некоторое время.',
        package_for_publish_description: 'Ниже перечисленные версии пакетов могут быть опубликованы:',
        package_publish_no_see_repo: 'Почему я не вижу нужный репозиторий?',


        list: 'Список репозиториев',
        validation_id_required: 'Требуется указать идентификатор репозитория',
        validation_id_repo_no_found: 'Нет такого репозитория',
        validation_id_repo_no_suitable: 'Нет подходящего репозитория',
        validation_id_length: 'Идентификатор должен быть от 2 двух до 128 символов',
        validation_id_unavailable: 'Данные идентификатор недоступен для регистрации',
        validation_id_checking: 'Проверка доступности идентификатора',
        validation_desc_required: 'Необходимо дать описание пакету',

        package_published_header: 'Публикация',
        package_published_info: 'Ваш пакет опубликован!',

        repo_is_unavailable: 'Репозиторий ${repo} недоступен сейчас!',
    },
    releases: {
        tag_name: 'Тэг релиза',
        error_info: 'Информация об ошибке',
        no_errors: 'Ошибок не обнаружено',
        unknown_error: 'Неизвестная ошибка ${error_code}',
        error_duplicate_version_in_releases: 'Версия пакета ${version} дублируются в релизах',
        error_no_any_release: 'Нечего публиковать. Нет ни одного релиза.',
        error_no_manifest: 'Не обнаружен файл dochub.yaml',
        error_manifest_schema: 'Файл dochub.yaml не соответствует требуемому формату',
        error_multipackages: 'Поставка содержит несколько пакетов: ${packages}',
        error_limit_size_manifest: 'Размер файла dochub.yaml превышает ${limit_size}',
        how_fix_errors: 'Для исправления ошибок внесите необходимые корректировки в GitHub репозиторий и нажмите кнопку "Обновить".'
    },
    search: {
        search: 'Поиск',
        search_packages: 'Поиск пакетов',
        no_found: 'Ничего не найдено',
        no_found_retry: 'Измените запрос и повторите попытку.',
    },
    prompts: {
        tbd: 'В разработке...',
        get_started: 'Как опубликовать?',
        no_found_repo: `
            <h3>Я не вижу необходимого мне репозитория.</h3>
            <p>
                В списке отображаются только репозитории, к которым у вас есть соответствующий уровень доступа.
                Существуют два типа репозиториев с различными требованиями:
            </p>
            <ul>
            <li><strong>Репозитории организаций:</strong> для доступа к ним вы должны иметь права майнтейнера.</li>
            <li><strong>Репозитории частных лиц:</strong> для доступа к ним требуется право на выполнение операции push.</li>
            </ul>
            <h3>Я майнтейнер в организации но репозитория все равно не вижу.</h3>
            <p>
                При авторизации через GitHub, вам предлагается дать согласие на доступ к вашим данным.
                <strong>Доступ к информации организаций необходимо предоставлять отдельно.</strong>
                Для этого вам потребуется выбрать соответствующую организацию внизу формы и подтвердить предоставление доступа.        
            </p>
        `
    },
    pages: {
        how_to_publish: `
        <h1>Публикация архитектурного пакета</h1>
        <p>
          Archpkg устроен очень просто. Он является реестром GitHub репозиториев, где расположены архитектурные 
          пакеты созданные с использованием <a target="_blank" href="https://dochub.info">DocHub</a>. 
          Владельцы репозиториев самостоятельно принимают решение о включении репозитория в реестр archpkg. 
        </p>
        <p>
          Несмотря на свою простоту, он позволяет сообществу использующему подход "архитектура как код" 
          накапливать, каталогизировать и обмениваться накопленным опытом выраженным в коде архитектуры.
          Идея archpkg заимствована у доказавших свою состоятельность менеджеров пакетов, например, 
          <a target="_blank" href="https://www.npmjs.com/">npm</a> и <a target="_blank" href="https://pypi.org/project/pip/">pip</a>.
        </p>
        <p>
          Полезные ресурсы:
          <ul>
            <li>
              Пример опубликованного архитектурного пакета можно посмотреть 
              <a target="_blank" href="https://github.com/rpiontik/DocHubExamples">здесь</a>.
            </li>
            <li>
              Документация по использованию archpkg находится 
              <a target="_blank" href="https://www.npmjs.com/package/archpkg">здесь</a>. 
            </li>
          </ul>
        </p>
        <h2>Подготовка к публикации</h2>
        <p>
          1. Создайте проект с архитектурным кодом <a target="_blank" href="https://dochub.info">DocHub</a>, которым вы хотите поделиться с сообществом. <br>
          2. В корне проекта разместите корректно заполненный файл <a href="#dochub_yaml">dochub.yaml</a>. Именно он имеет решающее значение для публикации пакета. 
             Пример <a target="_blank" href="https://github.com/rpiontik/DocHubExamples/blob/main/dochub.yaml">здесь</a>.<br>
          3. Опубликуйте проект на GitHub.<br>
          4. Обязательно произведите выпуск релиза. Пример подготовленного репозитория <a href="https://github.com/rpiontik/DocHubExamples">здесь</a>.<br>
        </p>
        <p>
          <strong>ВАЖНО:</strong> Если в своем проекте вы используете зависимости archpkg, не нужно их исходный код добавлять в ваш проект на GitHub. 
          Достаточно указать эти зависимости в секции "dependencies" в файле dochub.yaml. Подробнее <a href="#dochub_yaml">ниже</a>. Папку "_metamodel_" с исходными кодами зависимостей 
          разумно включить в .gitignore проекта. 
        </p>
        <p>
          Теперь ваш пакет готов к публикации в реестре.
        </p>
        <h2>Публикация в реестре</h2>
        <p>
          1. Авторизируйтесь на портале используя свою учетную запись в GitHub. 
        </p>
        <p>
          При предоставлении согласия на доступ к данным вашей учетной записи, удостоверьтесь в том,
          что он также предоставлен к необходимым организациям. Доступ к ним автоматически не предоставляется.
          Вам требуется отдельно его предоставить внизу формы.
        </p>
        <p>
          2. После авторизации, слева, появится меню разработчика. Выберите пункт <a href="/my_packages">"Мои пакеты"</a>. 
          Вы перейдете в список доступных вам уже опубликованных пакетов.
        </p>
        <p>
          <strong>ВНИМАНИЕ</strong>: Даже если вы впервые авторизировались на портале archpkg, список может содержать записи. Это возможно в том случае,
          если вы имеете необходимый доступ к репозиториям, которые ранее были опубликованы другим пользователем. Данная функциональность 
          позволяет управлять публикацией пакетов вашей доверенной группой. 
        </p>
        <p>
          3. Над списком пакетов нажмите кнопку <a href="/my_packages/publication">"Опубликовать пакет"</a>. Вы попадете в форму публикации.<br>
          4. Следуйте указаниям формы.<br>
          5. После успешного завершения публикации в списке пакетов появится новая запись.
        </p>
        <p>
          Поздравляем! Ваш пакет опубликован. 
        </p>
        <h2>Выпуск нового релиза пакета</h2>
        <p>
          Если в вашем репозитории пакета появился очередной релиз, в реестре информация о нем не появится автоматически. 
          Вам необходимо обновить информацию принудительно. Для этого:
        </p>
        <p>
          1. Авторизируйтесь на портале используя свою учетную запись в GitHub.<br>
          2. После авторизации, слева, появится меню разработчика. Выберите пункт <a href="/my_packages">"Мои пакеты"</a>. 
          Вы перейдете в список доступных вам уже опубликованных пакетов.<br>
          3. В списке найдите необходимую запись и нажмите кнопку "Обновить пакет" (облачко справа).<br>
          4. Следуйте указаниям формы.<br>
          5. После успешного завершения публикации в списке пакетов появится обновленная запись.
        </p>
        <p>Для автоматизации ризного процесса используйте <a href="/pages/api">API</a>.</p>
  
        <h2 id="dochub_yaml">Формат файла dochub.yaml</h2>
        Именно содержимое файла dochub.yaml определяет всю информацию о публикуемом пакете. Для каждого релиза файл может быть уникальным. 
        В том числе, может менять идентификатор пакета.<br><br>
        <pre class="code">
<span class="comment"># Метаданные пакета</span>
$package:                                      <span class="comment"># Обязательная секция метаданных пакета</span>
    &lt;идентификатор пакета&gt;:                    <span class="comment"># Идентификатор пакета должен соответствовать regex ^[0-9a-zA-Z\\-]{3,128}$ </span>
        version: &lt;версия пакета&gt;               <span class="comment"># Версия пакета в формата &lt;MAJOR&gt;.&lt;MINOR&gt;.&lt;PATCH&gt; подробнее <a target="blank" href="https://semver.org/">здесь</a></span>
        vendor: &lt;поставщик пакета&gt;             <span class="comment"># Информация о поставщике пакета</span>
        name: &lt;краткое название пакета&gt;        <span class="comment"># Используется для индексации в реестре</span>
        description: &lt;описание пакета&gt;         <span class="comment"># Используется для индексации в реестре</span>
        dependencies:                          <span class="comment"># Здесь указываются зависимости от других опубликованных пакетов</span>
            &lt;идентификатор пакета&gt;: &lt;версия&gt;   <span class="comment"># Версия указывается в формате &lt;MAJOR&gt;.&lt;MINOR&gt;.&lt;PATCH&gt; подробнее <a target="blank" href="https://semver.org/">здесь</a></span>
                                               <span class="comment"># Доступно указание правил выбора пакетов</span>
                                               <span class="comment"># >   выше указанной версии, например >1.2.11</span>
                                               <span class="comment"># >=  выше или равно указанной версии, например >=1.2.11</span>
...
    </pre>
    <strong>ВНИМАНИЕ</strong>: Допустимо использовать только одну запись идентификатора пакета при публикации.<br><br>
    <p>
        Пример файла <a target="_blank" href="https://github.com/rpiontik/DocHubExamples/blob/main/dochub.yaml">dochub.yaml</a>
        <pre class="code">
$package:
    dochub-examples:
        name: Примеры использования DocHub
        vendor: Сообщество DocHub
        description: >
            Репозиторий содержит примеры использования инструмента управления архитектурой DocHub.
            Репозиторий развивается силами сообщества.
        version: 1.2.0
        dependencies:
            dochub: ">=3.8.0"

imports:
    - src/C4Model/dochub.yaml      
         </pre>
        </p>                
        `,
        main: {
            tagline: 'Проектируем будущее вместе!',
            content: `
            <p>
                Добро пожаловать в репозиторий архитектурного кода <a target="_blank"
                href="https://dochub.info/main">DocHub</a>!
            </p>
            <p>
                Archpkg это менеджер пакетов. Он обеспечивает управление зависимостями архитектурных репозиториев созданных с
                использованием подхода "Архитектура как код". Позволяет быстро и удобно устанавливать, обновлять и удалять
                пакеты, необходимые для работы архитектурного репозитория.
            </p>
            <p>
                Archpkg разработан для того, чтобы стимулировать развитие архитектурной функции открытым сообществом.
                Призван обеспечить обмен опытом и знаниями выраженными в архитектурном коде между членами сообщества.
            </p>
            <p>
                Archpkg инструментализирует концепцию адаптивной метамодели. Подробнее о ней и структуре пакетов
                <a target="_blank" href="https://dochub.info/entities/docs/blank?dh-doc-id=dochub.flex_metamodel">здесь</a>.
            </p>
        
            <p>
                Archpkg это JavaScript CLI утилита требующая для свой работы <a target="_blank"
                href="https://docs.npmjs.com/about-npm">npm</a>
                и <a target="_blank" href="https://nodejs.org/en/">nodejs</a>. Актуальная информация о ее требованиях и
                инструкция по использованию
                находится на странице утилиты в репозитории npm - <a target="_blank"
                href="https://www.npmjs.com/package/archpkg">https://www.npmjs.com/package/archpkg</a>
            </p>
            <h4>Пример использования:</h4>
            <pre tabindex="0" class="code">mkdir examples\ncd examples\nnpx archpkg install dochub-examples -save</pre>
            `
        },
        
        footer: {
            support: 'Поддержка',
            support_telegram: 'Группа Telegram',
            support_github: 'Тикеты GitHub',
            support_bug: 'Сообщить об ошибке',

            community: 'Сообщество',
            community_telegram: 'Группа Telegram',
            community_channel: 'Канал Telegram',
            community_articles: 'Статьи', 
            community_videos: 'Видео', 

            policies: 'Условия и политика',
            policies_principe: 'Принципы развития',
            policies_license: 'Лицензия'
        }
    },
    actions: {
        create: 'Создать',
        refresh: 'Обновить',
        package_update: 'Обновить пакет',
    }

}