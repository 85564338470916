<template>
  <div>
    <div class="app-header mx-auto my-12">
      <template v-if="isNoFound">
        <h2>{{ $lang.messages.search.no_found }}</h2>
        <v-subheader>{{ $lang.messages.search.no_found_retry }}</v-subheader>
      </template>
      <template v-else>
        <v-progress-linear v-if="isLoading" indeterminate color="cyan"></v-progress-linear>
        <div v-else class="loading-placeholder"></div>
        <v-pagination v-if="isShowPagination" v-model="page" :length="countPage" :total-visible="5"></v-pagination>
        <SearchItem v-for="item in items" :key="item.package_id" :packageId="item.package_id" :name="item.name"
          :description="item.description" :vendor="item.vendor" :repo="item.repo"
          :version="`${item.version}.${item.version_sub}.${item.version_fix}`">
        </SearchItem>
        <v-pagination v-if="isShowPagination" v-model="page" :length="countPage" :total-visible="5"></v-pagination>
      </template>
    </div>
    <AppFooter />
  </div>
</template>

<script>

import backend from '@/backend/api';
import SearchItem from './SearchItem.vue';

export default {
  name: 'RepositorySearch',
  components: {
    SearchItem
  },
  props: {
    text: String
  },
  data() {
    return {
      page: 0,
      countPage: 0,
      items: [],
      isLoading: false,
      isNoFound: false
    };
  },
  mounted() {
    this.$store.commit('setSearchText', this.text);
    this.restart();
  },
  watch: {
    text() {
      this.restart();
    },
    page() {
      this.reload();
    }
  },
  computed: {
    isShowPagination() {
      return this.countPage > 1;
    }
  },
  methods: {
    restart() {
      this.page = 1;
      this.countPage = 1;
      this.isNoFound = false;
      this.reload();
    },
    reload() {
      this.isLoading = true;
      backend.searchPackage(this.text || '*', this.page - 1)
        .then((data) => {
          this.items = data.items;
          data.more && (this.countPage < (this.page + 1)) && (this.countPage++)
          this.isNoFound = !data.items.length;
        })
        .finally(() => this.isLoading = false);
    }
  }
}
</script>

<style scoped>
.loading-placeholder {
  height: 4px;
}
</style>
