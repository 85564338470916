<template>
  <div>
    <v-divider></v-divider>
    <div class="app-footer mx-auto my-12">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="2">
          <div class="link-icon">
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.npmjs.com/package/archpkg"><svg
                viewBox="0 0 27.23 27.23" aria-hidden="true">
                <rect fill="#333333" width="27.23" height="27.23" rx="2"></rect>
                <polygon fill="#fff"
                  points="5.8 21.75 13.66 21.75 13.67 9.98 17.59 9.98 17.58 21.76 21.51 21.76 21.52 6.06 5.82 6.04 5.8 21.75">
                </polygon>
              </svg>
            </a>
          </div>
          <div class="link-icon">
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://github.com/rpiontik/archpkg"
              aria-label="GitHub"><svg aria-hidden="true" role="img" class="" viewBox="0 0 16 16" width="50" height="50"
                fill="black" style="display:inline-block;user-select:none;vertical-align:top">
                <path fill-rule="evenodd"
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z">
                </path>
              </svg></a>
          </div>
        </v-col>
        <v-col class="text-top footer-column" cols="3">
          <h4>{{ $lang.messages.pages.footer.support }}</h4>
          <ul class="column-item">
            <li><a target="_blank" href="https://t.me/archascode">{{ $lang.messages.pages.footer.support_telegram }}</a>
            </li>
            <li><a target="_blank" href="https://github.com/rpiontik/archpkg/issues">{{
              $lang.messages.pages.footer.support_github }}</a></li>
            <li><a target="_blank" href="https://github.com/rpiontik/archpkg/issues">{{
              $lang.messages.pages.footer.support_bug }}</a></li>
          </ul>
        </v-col>
        <v-col class="text-top footer-column" cols="3">
          <h4>{{ $lang.messages.pages.footer.community }}</h4>
          <ul class="column-item">
            <li><a target="_blank" href="https://t.me/archascode">{{ $lang.messages.pages.footer.community_telegram
                }}</a></li>
            <li><a target="_blank" href="https://t.me/dochubchannel">{{ $lang.messages.pages.footer.community_channel
                }}</a></li>
            <li><a target="_blank"
                href="https://github.com/RabotaRu/dochub?tab=readme-ov-file#%D1%81%D1%82%D0%B0%D1%82%D1%8C%D0%B8">{{
              $lang.messages.pages.footer.community_articles }}</a></li>
            <li><a target="_blank" href="https://www.youtube.com/@user-eq5pj3zk6w/videos">{{
              $lang.messages.pages.footer.community_videos }}</a></li>
          </ul>
        </v-col>
        <v-col class="text-top footer-column" cols="3">
          <h4>{{ $lang.messages.pages.footer.policies }}</h4>
          <ul class="column-item">
            <li><a target="_blank"
                href="https://github.com/RabotaRu/dochub?tab=readme-ov-file#-%D0%BF%D1%80%D0%B8%D0%BD%D1%86%D0%B8%D0%BF%D1%8B-%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D1%82%D0%B8%D1%8F-%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D0%B0">{{
              $lang.messages.pages.footer.policies_principe }}</a></li>
            <li><a target="_blank" href="https://github.com/rpiontik/archpkg?tab=GPL-3.0-1-ov-file#readme">{{
                $lang.messages.pages.footer.policies_license }}</a></li>
          </ul>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>

export default {
  name: 'AppFooter',
  data() {
    this.$lang.setLang('en');
    return {
    }
  }
}
</script>

<style scoped>

.footer-column {
  height: 280px;
}

.link-icon {
  max-width: 64px;
  margin-bottom: 16px;
}

.column-item {
  margin-top: 16px;
  list-style-type: none;
}

.column-item li {
  margin-left: -24px;
}
</style>
