<template>
  <div>
    <div class="app-document mx-auto my-12">
      <h1>{{ $lang.messages.prompts.tbd }}</h1>
    </div>
    <AppFooter/>
  </div>
</template>

<script>


export default {
  name: 'MainPage',
  data() {
    this.$lang.setLang('en');
    return {
    }
  }
}
</script>

<style scoped>

.parallax {
  max-width: 1024px;
}

.footer {
  max-width: 1024px;
}

.footer-column {
  height: 280px;
}

.link-icon {
  max-width: 64px;
  margin-bottom: 16px;
}

.column-item {
  margin-top: 16px;
  list-style-type: none;
}

.column-item li {
  margin-left: -24px;
}
</style>
