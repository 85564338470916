import consts from './consts';
import { v4 as uuidv4 } from 'uuid';
import cookie from 'vue-cookie';


function makeGitHubLoginURL(redirectURL) {
    const oauthURL = new URL(process.env.VUE_APP_OAUTH_GITHUB_LOGIN);
    oauthURL.searchParams.set('client_id', process.env.VUE_APP_OAUTH_GITHUB_CLIENT_ID);

    const state = cookie.get(consts.oauth.github.stateCookie) || uuidv4();
    oauthURL.searchParams.set('state', state);
    oauthURL.searchParams.set('scope', 'public_repo read:user user:email read:project');

    cookie.set(consts.oauth.github.stateCookie, state, { expires: consts.oauth.github.stateExpires });
    cookie.set(`redirect_${state}`, redirectURL || '/', { expires: consts.oauth.github.stateExpires });

    return oauthURL.toString();
}

function cleanGitHubLoginURL() {
    cookie.delete(consts.oauth.github.stateCookie);
}

export default  {
    oauth: {
        github:  {
            makeLoginURL: makeGitHubLoginURL,
            cleanLoginURL: cleanGitHubLoginURL,
            clientID: process.env.VUE_APP_OAUTH_GITHUB_CLIENT_ID
        }
    },
    backend: {
        url: process.env.VUE_APP_BACKEND_URL
    }
}