<template>
  <div>
    <a @click="onShow">{{ title }}</a>
    <v-bottom-sheet v-model="show">
      <v-sheet
        style="width: 100%;"
        
      >
      <div @click="onClose" v-html="content" class="prompt-content"></div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>

export default {
  name: 'AppPrompt',
  props: {
    title: String,
    content: String
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onShow() {
      this.show = true;
    }
  },
  data() {
    return {
      show: false
    }
  }
}
</script>

<style >
  .v-bottom-sheet {
    width: 100%;
    max-width: 1024px;
  }

  .prompt-content {
    padding: 8px;
  }

  .prompt-content h3 {
    margin-top: 12px;
    margin-bottom: 8px;
  }

  .prompt-content p {
    margin-left: 12px;
  }
</style>
