<template>
  <v-app id="inspire">
    <v-app-bar app clipped-left style="height: 64px; padding-top: 4px;">
      <template v-if="isLogin">
        <v-app-bar-nav-icon @click="handleDrawer"></v-app-bar-nav-icon>
      </template>

      <a @click.stop="onLogoClick"><archpkg-logo :title="false" class="header-logo" /></a>
      <v-toolbar-title class="hidden-sm-and-down" style="cursor: pointer" @click="onLogoClick">

        Archpkg
      </v-toolbar-title>
      <search class="app-search-input"></search>
      <div class="get-started text-center"><router-link :to="{ name: 'howToPublish'}">{{ $lang.messages.prompts.get_started }}</router-link></div>
      <v-spacer />
      
      <account-avatar />
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app clipped v-if="isLogin">
      <v-list>
        <v-list-item v-for="item in links" :key="item.icon" :class="route === item.route ? 'menu-selected' : ''" link
          @click="onClickMenuItem(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container class="py-8 px-6" fluid>
        <router-view />
      </v-container>

      <v-dialog v-model="isShowAlerts" min-width="500" max-height="400">
        <v-alert border="left" colored-border type="error" elevation="2" style="margin: 0;" v-for="(alert) in alerts"
          :key="alert.content">
          <pre>{{ alert.content }}</pre>
        </v-alert>
        <v-btn @click="onCleanAlerts" color="primary">Close</v-btn>
      </v-dialog>

    </v-main>
  </v-app>
</template>


<script>

import archpkgLogo from './design/Archpkg.vue';
import accountAvatar from './account/Account.vue';
import search from '@/components/repository/SearchField.vue';


export default {
  name: 'App',
  components: {
    archpkgLogo,
    accountAvatar,
    search
  },
  mounted() {
    this.$store.commit('refreshLoginStatus');
  },
  methods: {
    onCleanAlerts() {
      this.$store.commit('cleanAlert');
    },

    onLogoClick() {
      this.route !== 'main' && this.$router.push({ name: 'main' });
    },

    onClickMenuItem(item) {
      this.route !== item.route && this.$router.push({ name: item.route });
    },

    handleDrawer() {
      this.drawer = !this.drawer;
    }
  },
  computed: {
    alerts() {
      return this.$store.state.alerts || [];
    },
    isShowAlerts() {
      return !!this.alerts.length;
    },
    isLogin() {
      return this.$store.state.account.isLogin;
    },
    route() {
      return this.$route.name;
    },
    links() {
      const result = [];
      if (this.$lang) {
        this.isLogin
        && result.push({
          icon: 'mdi-package-variant-closed',
          title: this.$lang.messages.repo.my_packages,
          route: 'myPackages'
        })
        && result.push({
          icon: 'mdi-api',
          title: 'API',
          route: 'api'
        })
        ;
      }
      return result;
    }
  },
  watch: {
    isLogin(value) {
      value && (this.drawer = true);
    }

  },
  data: () => ({
    drawer: true,
    hasan: 'mdi-source-repository-multiple'
  })
}
</script>

<style>
.header-logo {
  height: 42px;
  width: auto;
}

.account {
  max-height: 100%;
}

.account .v-avatar {
  margin-top: 4px;
}

.menu-selected {
  background-color: #BDBDBD;
  cursor: pointer;
}

.app-search-input {
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-top: 24px !important;
}

.app-footer {
  max-width: 1024px;
}

.app-header {
  max-width: 1024px;
}

.app-content {
  max-width: 600px;
}

.app-document {
  max-width: 1024px;
}

.app-document h1 {
  margin-bottom: 32px;
}


.app-document h2 {
  margin-top: 32px;
  margin-bottom: 12px;
}

.app-document h3 {
  margin-top: 16px;
  margin-bottom: 12px;
}

.app-document ul {
  margin-top: 24px;
  margin-bottom: 24px;
}

.app-document pre.code,
.app-content pre.code {
  margin-top: 0;
  margin-bottom: 24px;
  padding: 12px;
  font-size: 1em;
  background: #f7f7f7;
  border-radius: 2px;
  overflow-x: auto;
  color: #005cc5;
}

.app-document pre.code .comment,
.app-content pre.code .comment {
  color: #6a737d;;
}

.app-document pre.code .key,
.app-content pre.code .key {
  color: #22863a;
}

.get-started {
  margin-left: 6px;
  margin-right: 6px;
}

</style>
