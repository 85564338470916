<template>
  <v-text-field :label="$lang.messages.search.search_packages" outlined prepend-inner-icon="mdi-magnify" v-on:keyup.enter="goSearch" v-model="search">
    <v-btn class="btn-search hidden-sm-and-down" slot="append" color="primary" @click="goSearch">
      {{ $lang.messages.search.search }}
    </v-btn>
  </v-text-field>
</template>

<script>

export default {
  name: 'SearchField',
  data() {
    return {
    };
  },
  methods: {
    goSearch() {
      this.$router
        .push({ name: 'search', params: { text: this.search}})
        .catch(() => false);
    }
  },
  computed: {
    search: {
      get() {
        return this.$store.state.search.text;
      },
      set(value) {
        this.$store.commit('setSearchText', value);
      }
    }
  }
}

</script>

<style scoped>

.btn-search {
  margin-top: -8px;
}

</style>
