<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <h1>{{ $lang.messages.repo.package_publish_header }}</h1>
    <v-timeline align-top dense>
      <v-timeline-item :color="stepRepo.color" :icon="stepRepo.icon" :small="stepRepo.small" fill-dot>
        <v-row>
          <v-col cols="6" sm="12" md="6">
            <strong>1. {{ $lang.messages.repo.package_publish_select_repo }}</strong><br>
            {{ $lang.messages.repo.package_publish_repo_description }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="12" md="6">
            <el-repo v-model="repo" :initRepo="initRepo" :disabled="isBusy" />
            <el-prompt :title="$lang.messages.repo.package_publish_no_see_repo" :content="$lang.messages.prompts.no_found_repo"></el-prompt>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item :color="stepPrepare.color" :icon="stepPrepare.icon" :small="stepPrepare.small" fill-dot>
        <v-row>
          <v-col cols="3" sm="12" md="6">
            <strong>2. {{ $lang.messages.repo.package_prepare_to_publish }}</strong><br>
            <span v-if="repo"> {{ $lang.messages.repo.package_publish_prepare_description }} </span>
          </v-col>
        </v-row>
        <v-row v-if="repo">
          <v-col cols="6" sm="12" md="6">
            <v-progress-circular v-if="this.isDistribBuilding" indeterminate color="primary"></v-progress-circular>
            <v-btn v-else class="mr-4" :disabled="!repo || isBusy" @click="onMakeDistrib">
              {{ this.distrib || this.errors?.length ? $lang.messages.actions.refresh : $lang.messages.repo.package_prepare }}
            </v-btn>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item :color="stepError.color" :icon="stepError.icon" :small="stepError.small" fill-dot>
        <v-row>
          <v-col cols="3" sm="12" md="3">
            <strong>3. {{ $lang.messages.repo.package_fix_errors }}</strong>
          </v-col>
        </v-row>
        <v-row v-if="errors?.length">
          <v-col cols="12" sm="12" md="12">
            {{ $lang.messages.releases.how_fix_errors }}
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="errors?.length" cols="12" sm="12" md="12">
            <el-errors :errors="errors" />
          </v-col>
          <v-col v-else-if="this.distrib" cols="6" sm="12" md="6">
            <strong>{{ $lang.messages.releases.no_errors }}</strong>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item :color="userCheck.color" :icon="userCheck.icon" :small="userCheck.small" fill-dot>
        <v-row>
          <v-col cols="3" sm="12" md="3">
            <strong>4. {{ $lang.messages.repo.package_user_check }}</strong>
          </v-col>
        </v-row>
        <v-row v-if="distrib">
          <v-col cols="12" sm="12" md="12">
            {{ $lang.messages.repo.package_for_publish_description }}
            <v-expansion-panels>
              <v-expansion-panel v-for="(item) in distribMetadata" :key="item.fullVersion">
                <v-expansion-panel-header>
                  <strong>{{ item.fullVersion }}</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="2" sm="12" md="2"><v-subheader>Name:</v-subheader></v-col>
                    <v-col cols="10" sm="12" md="10">{{ item.packed.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" sm="12" md="2"><v-subheader>Description:</v-subheader></v-col>
                    <v-col cols="10" sm="12" md="10">{{ item.packed.description }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" sm="12" md="2"><v-subheader>Vendor:</v-subheader></v-col>
                    <v-col cols="10" sm="12" md="10">{{ item.packed.vendor }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" sm="12" md="2"><v-subheader>Dependencies:</v-subheader></v-col>
                    <v-col cols="10" sm="12" md="10">{{ Object.keys(item.packed.dependencies || {}).join('; ')
                      }}</v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item :color="publishCheck.color" :icon="publishCheck.icon" :small="publishCheck.small" fill-dot>
        <v-row>
          <v-col cols="3" sm="12" md="6">
            <strong>5. {{ $lang.messages.repo.package_publish_to_repository }}</strong><br>
            <span v-if="distrib">{{ $lang.messages.repo.package_publish_description }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-progress-circular v-if="isPublishingDistrib" indeterminate color="primary"></v-progress-circular>
          <v-btn class="mr-4" v-else-if="distrib" color="primary" @click="onPublish" :disabled="isBusy">
            {{ $lang.messages.repo.package_publish_header }}
          </v-btn>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <el-published v-if="isPublished"/>
  </v-form>
</template>

<script>

import elRepo from './elements/Repo.vue';
import elErrors from './elements/Errors.vue';
import elPublished from './elements/Published.vue';
import elPrompt from '@/components/elements/Prompt.vue';

import backendAPI from '@/backend/api';

export default {
  name: 'PublicationPackage',
  components: {
    elRepo,
    elErrors,
    elPublished,
    elPrompt
  },
  props: {
    initRepo: String
  },
  data() {
    return {
      valid: false,
      repo: null,
      repoSelected: null,
      distrib: null,
      isDistribBuilding: false,
      isPublishingDistrib: false,
      errors: null,
      isPublished: false
    }
  },
  watch: {
    repoSelected() {
      this.distrib = null;
      this.errors = null;
    }
  },
  computed: {
    isBusy() {
      return this.isDistribBuilding || this.isPublishingDistrib;
    },
    stepRepo() {
      return {
        small: false,
        color: this.repo ? 'green' : 'primary lighten-1',
        icon: this.repo ? 'mdi-check-circle-outline' : 'mdi-pencil-circle-outline'
      }
    },
    stepPrepare() {
      return {
        small: !this.repo,
        color: this.distrib ? 'green' : 'primary lighten-1',
        icon: !this.repo ? '' : (this.distrib ? 'mdi-check-circle-outline' : 'mdi-pencil-circle-outline')
      }
    },
    stepError() {
      return {
        small: !this.distrib && !this.errors?.length,
        color: this.distrib ? (this.errors?.length ? 'orange darken-4' : 'green') : (this.errors?.length ? 'red' : 'primary lighten-1'),
        icon: this.errors?.length ? 'mdi-alert' : (!this.distrib ? '' : 'mdi-check-circle-outline')
      }
    },
    userCheck() {
      return {
        small: !this.distrib,
        color: this.distrib ? 'green' : 'primary lighten-1',
        icon: this.distrib ? 'mdi-check-circle-outline' : ''
      }
    },
    publishCheck() {
      return {
        small: !this.distrib,
        color: 'primary lighten-1',
        icon: this.distrib ? 'mdi-pencil-circle-outline' : ''
      }
    },
    distribMetadata() {
      const result = [];
      for (const fullVersion in this.distrib.data.versions || {}) {
        const record = this.distrib.data.versions[fullVersion];
        const packedId = record.id;
        const packed = record.manifest;

        result.push({
          fullVersion,
          packedId,
          tag: record.tag,
          packed
        });
      }
      return result;
    }
  },

  methods: {
    doPublishDistrib() {
      this.isPublishingDistrib = true;
      backendAPI.publishPackageDistribution(this.distrib).then(() => {
        this.isPublished = true;
      }).finally(() => this.isPublishingDistrib = false);
    },
    doMakeDistrib() {
      this.isDistribBuilding = true;
      backendAPI.makePackageDistribution(this.repo.name).then((data) => {
        this.distrib = data.distrib;
        this.errors = data.errors;

        if (!this.errors?.length && !this.distrib) {
          this.errors.push({ id: 'no-any-release' });
        }

      }).finally(() => this.isDistribBuilding = false);
    },
    onMakeDistrib() {
      this.$refs.form.validate();
      this.$nextTick(() => this.valid && this.doMakeDistrib());
    },
    onPublish() {
      this.$refs.form.validate();
      this.$nextTick(() => this.valid && this.doPublishDistrib());
    }
  }
}
</script>

<style></style>
