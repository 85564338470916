<template>
  <div>
    Repository
  </div>
</template>
<script>

export default {
  name: 'GlobalRepository',
  data: () => ({

  }),

  created() {
  },

  methods: {
  }
}
</script>

<style></style>
