import axios from 'axios';
import env from '@/env';

function registerNetError(error) {
  const reason = error?.response?.data?.reason;
  window.$vuex.commit('pushAlert', {
    type: 'network-error',
    content: `url: ${error?.config?.url}\nMessage: ${error?.message}` 
      + (reason ? `\nReason: ${reason}`: '')
  });
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) {
    console.error(`Unauthorized request to  [${(error?.response?.config?.url || '').toString()}]`, error);

    let redirect_uri = window.location.pathname;
    window.location.search && (redirect_uri += `?${window.location.search}`);
    window.location.hash && (redirect_uri += `#${window.location.hash}`);
    
    window.location = env.oauth.github.makeLoginURL(redirect_uri);
  } else {
    registerNetError(error);
  }  
  return Promise.reject(error);
});

export default axios;