<template>
  <div class="account">

    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-avatar class="mb-4" color="grey darken-1" size="48" v-bind="attrs" v-on="on" :title="avatar_login">
          <img v-if="avatar_url" :src="avatar_url" :alt="avatar_login" >
          <v-icon v-else dark>
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <login />
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          
          <v-list-item-title>
            <v-divider ></v-divider>
            <langs />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </div>
</template>


<script>

import login from '../oauth/github/Login.vue';
import langs from '../langs/Langs.vue';

export default {
  name: 'App',
  components: {
    login,
    langs
  },
  computed: {
    avatar_url() {
      return this.$store.state.account.avatar_url;
    },
    avatar_login() {
      return this.$store.state.account.avatar_login;
    }
  },
  methods: {
  },
  data() {
    return {};
  }
}
</script>

<style scoped>
.header-logo {
  height: 42px;
  width: auto;
}

.account {
  max-height: 100%;
}

.account .v-avatar {
  margin-top: 4px;
}
</style>
