<template>
  <div>
    <a v-if="!isLogin" @click="login">GitHub {{ $lang.messages.account.login }}</a>
    <a v-else @click="logout">GitHub {{ $lang.messages.account.logout }}</a>
  </div>
</template>

<script>

import env from '@/env';
import githubAPI from '@/components/oauth/api';

export default {
  name: 'GigHubLogin',
  methods: {
    logout() {
      githubAPI.logout().then(() => {
        this.$store.commit('refreshLoginStatus');
        this.$router.push({ name: 'main' }).catch(()=>{});
      });
      
      return false;
    },
    login () {
      window.location = env.oauth.github.makeLoginURL();
      return false;
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.account.isLogin;
    },
  },
  data() {
    return {
    }
  }
}
</script>

<style></style>
