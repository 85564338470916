<template>
  <div>
    <v-data-table :headers="headers" :items="data" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $lang.messages.repo.package_publish_list }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn icon>
            <v-icon @click="reload" :title="$lang.messages.actions.refresh">
              mdi-reload
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="goPublication">
            {{ $lang.messages.repo.package_publish }}
          </v-btn>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.id="{ item }">
        <a @click.stop="goPackagePage(item.id)">
          {{ item.id }}
        </a>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.repo="{ item }">
        <a target="_blank" :href="`https://github.com/${item.repo}`">
          {{ item.repo }}
        </a>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="goUpdate(item)" :title="$lang.messages.actions.package_update">
          mdi-cloud-upload
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-progress-circular v-if="isReloading" indeterminate color="primary"></v-progress-circular>
        <v-btn v-else color="primary" @click="reload">
          {{ $lang.messages.actions.refresh }}
        </v-btn>
      </template>
    </v-data-table>
    <AppFooter />
  </div>
</template>
<script>

import backendAPI from '@/backend/api';

export default {
  name: 'UserPackages',
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },

    headers() {
      return [
        {
          text: this.$lang.messages.repo.package_id,
          align: 'start',
          sortable: true,
          value: 'id',
          width: '15%'
        },
        {
          text: this.$lang.messages.repo.package_desc,
          value: 'description',
          sortable: false,
          width: '30%'
        },
        {
          text: this.$lang.messages.repo.package_repo,
          value: 'repo',
          sortable: false,
          width: '20%'
        },
        {
          text: this.$lang.messages.repo.package_versions,
          value: 'versions',
          sortable: false,
          width: '30%'
        },
        {
          text: this.$lang.messages.repo.package_actions,
          value: 'actions',
          sortable: false,
          width: '5%'
        },
      ]
    }
  },
  data() {
    return {
      data: [],
      error: null,
      isReloading: false,
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.reload()
  },

  methods: {
    reload() {
      this.isReloading = true;
      this.data = [];
      backendAPI.fetchUserPackages()
        .then((data) => this.data = data)
        .catch((err) => {
          this.data = [];
          this.error = err;
        })
        .finally(() => this.isReloading = false);
    },

    goPackagePage(packageId) {
      this.$router.push({ name: 'search', params: { text: packageId} });
    },

    goPublication() {
      this.$router.push({ name: 'publicationPackage' });
    },


    goUpdate(item) {
      this.$router.push({ name: 'updatePackage', params: { initRepo: item.repo } });
    },
  }
}
</script>

<style></style>
