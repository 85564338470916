export default  {
    account: {
        isLogin: 'jwt-expires',
        avatar_url: 'avatar_url',
        avatar_login: 'avatar_login'
    },
    oauth: {
        github:  {
            stateCookie: 'oauth-gitlab-state',
            stateExpires: '1h',
            callback: '/oauth/github/callback'
        }
    },
    langs: {
        default: 'ru',
        ttlSelected: 60 * 60 * 24 * 365,
        availableLangs: ['ru', 'en']
    }
}