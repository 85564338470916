import cookie from 'vue-cookie';
import consts from '@/consts';

export default function (app) {
    const manager = {
        getCurrentLang() {
            return (cookie.get('lang') || window.navigator.language || consts.langs.default).split('-')[0];
        },
        setCurrentLang(selected) {
            cookie.set('lang', selected, { expires: consts.langs.ttlSelected });
            app.$lang.setLang(selected);
        },
        getAvailableLangs() {
            return consts.langs.availableLangs
        },
        refresh() {
            if (this.getCurrentLang() !== app.$lang.getLang())
                this.setCurrentLang(this.getCurrentLang());
        },
        template(template, context) {
            const contextCode = (Object.keys(context).map((key) => `const ${key} = context.${key}`)).join('; ');
            let result = '';
            eval(`${contextCode};result=\`${template}\`;`);
            return result;
        }

    };

    manager.refresh();
    app.$lang.manager = manager;

    return manager;
}