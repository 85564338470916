<template>
  <v-data-table :headers="headers" :items="items" class="elevation-1"></v-data-table>
</template>

<script>

export default {
  name: 'PublicationPackage',
  props: {
    errors: Array
  },
  methods: {
    makeInfo(template, context) {
      const contextCode = (Object.keys(context).map((key) => `const ${key} = context.${key}`)).join('; ');
      let result = '';
      eval(`${contextCode};result=\`${template}\`;`);
      return result;
    }
  },
  computed: {
    items() {
      return this.errors.map((error) => {
        const result = {
          tag: Array.isArray(error.tag) ? error.tag.join('; ') : error.tag,
        };
        switch (error.id) {
          case 'duplicate-version-in-releases':
            result.info = this.makeInfo(this.$lang.messages.releases.error_duplicate_version_in_releases, error);
            break;
          case 'multipackages':
          result.info = this.makeInfo(this.$lang.messages.releases.error_multipackages, { ...error, packages: error.packages.join('; ') });
            break;
          case 'schema-manifest':
            result.info = this.makeInfo(this.$lang.messages.releases.error_manifest_schema, error);
            break;
          case 'limit-size-manifest':
            result.info = this.makeInfo(this.$lang.messages.releases.error_limit_size_manifest, error);
            break;
          case 'no-manifest':
            result.info = this.makeInfo(this.$lang.messages.releases.error_no_manifest, error);
            break;
          case 'no-any-release':
            result.info = this.makeInfo(this.$lang.messages.releases.error_no_any_release, error);
            break;
          default:
            console.error('Undefined distrib error ', error);
            result.info = result.info = this.makeInfo(this.$lang.messages.releases.unknown_error, { error_code: error.id});
        }
        return result;
      });
    },
   
    headers() {
      return [
        {
          text: this.$lang.messages.releases.tag_name,
          align: 'start',
          sortable: true,
          value: 'tag',
          width: '20%'
        },
        {
          text: this.$lang.messages.releases.error_info,
          value: 'info'
        }
      ];
    }
  },
  data() {
    return {
    }
  },

}
</script>

<style></style>
